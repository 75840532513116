import { Component, Vue, VModel } from "vue-property-decorator";

@Component
export default class Avatars extends Vue {
  @VModel({ type: String })
  private activeAvatarName!: string;

  private get avatarGroups() {
    return [
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.avatar_objects"),
        items: [
          {
            avatarName: "smp-21",
          },
          {
            avatarName: "smp-22",
          },
          {
            avatarName: "smp-23",
          },
          {
            avatarName: "smp-24",
          },
          {
            avatarName: "smp-25",
          },
          {
            avatarName: "smp-26",
          },
          {
            avatarName: "smp-27",
          },
          {
            avatarName: "smp-28",
          },
          {
            avatarName: "smp-29",
          },
          {
            avatarName: "smp-30",
          },
        ],
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.avatar_animals"),
        items: [
          {
            avatarName: "smp-11",
          },
          {
            avatarName: "smp-12",
          },
          {
            avatarName: "smp-13",
          },
          {
            avatarName: "smp-14",
          },
          {
            avatarName: "smp-15",
          },
          {
            avatarName: "smp-16",
          },
          {
            avatarName: "smp-17",
          },
          {
            avatarName: "smp-18",
          },
          {
            avatarName: "smp-19",
          },
          {
            avatarName: "smp-20",
          },
        ],
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.avatar_people"),
        items: [
          {
            avatarName: "smp-1",
          },
          {
            avatarName: "smp-2",
          },
          {
            avatarName: "smp-3",
          },
          {
            avatarName: "smp-4",
          },
          {
            avatarName: "smp-5",
          },
          {
            avatarName: "smp-6",
          },
          {
            avatarName: "smp-7",
          },
          {
            avatarName: "smp-8",
          },
          {
            avatarName: "smp-9",
          },
          {
            avatarName: "smp-10",
          },
        ],
      },
    ];
  }

  private get avatarSize() {
    return this.$vuetify.breakpoint.mobile ? 60 : 104;
  }

  private onClickAvatar(avatarName: string) {
    this.activeAvatarName = avatarName;
  }
}
